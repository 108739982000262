import React, { useState, useEffect } from 'react';
import { Box, Icon, Typography, useTheme, Switch } from '@mui/material';
import { tokens } from '../theme';
import falseSound from '../assets/sounds/false.wav';
import fastBleepSound from '../assets/sounds/fast_bleep.wav';

const Control = ({ title, subtitle, icon, progress, duration, onChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const angle = progress * 360;
  const textColor = progress ? colors.greenAccent[500] : colors.redAccent[500];

  const [isChecked, setIsChecked] = useState(progress > 0);

  const handleToggle = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    onChange(newChecked);

    // Use a timeout to update the switch position after a duration
    const newDuration = duration * 1000; // Specify the duration in milliseconds
    if (newDuration > 0) {
      setTimeout(() => {
        setIsChecked(false); // Update the switch position after the timeout
      }, newDuration);
    }
  };

  // Use useEffect to reset the switch position when isChecked changes
  useEffect(() => {
    setIsChecked(progress > 0);
  }, [progress]);

  // Use useEffect to play the false sound when isChecked becomes false
  useEffect(() => {
    if (isChecked) {
      const audio = new window.Audio(fastBleepSound);
      audio.play().catch((error) => {
        // Handle play error, if any
        console.error('Failed to play audio:', error);
      });
    } else {
      const audio = new window.Audio(falseSound);
      audio.play().catch((error) => {
        // Handle play error, if any
        console.error('Failed to play audio:', error);
      });
    }
  }, [isChecked]);

  return (
    <Box>
      <Box>
        <Box
          sx={{
            background: `radial-gradient(transparent 45%, ${textColor} 75%),
                      conic-gradient(transparent 0deg ${angle}deg, transparent ${angle}deg 360deg),
                      transparent`,
            borderRadius: '50%',
            width: `100px`,
            height: `100px`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Icon
            sx={{
              marginTop: '-40px',
              fontSize: '48px',
              color: textColor,
            }}
          >
            {icon}
          </Icon>
          <Typography mb="5px">{title}</Typography>
        </Box>
      </Box>

      <Box ml="-29px">
        <Box>
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <Switch
              backgroundColor="transparent"
              checked={isChecked}
              onChange={handleToggle}
              sx={{
                '& .MuiSwitch-thumb': {
                  backgroundColor: isChecked
                    ? colors.greenAccent[400]
                    : colors.redAccent[400],
                },
                '& .MuiSwitch-track': {
                  backgroundColor: isChecked
                    ? '#81c784'
                    : '#e57373', // Track color
                },
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Control;
