import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import Header from '../../components/Header';

const Connection = () => {
  const handleConnectClick = () => {
    // Open the link in a new tab or window
    window.open('http://192.168.4.1/connect', '_blank');
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "70px",}}>
        <Header title="WiFi SETUP"/>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Header subtitle="Guide to setup ur wifi connection for SMART ECO TOILET :"/>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Typography>
          1. Connect to WiFi name: "ESP32-Access-Point" password: "password".
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "10px",}}>
        <Typography>
          (Disable any working internet connection etc. mobile data).
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Typography>
          2. Click the button below and fill in your new WiFi ID and Password.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
      <button onClick={handleConnectClick}>Local Connection Page</button>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Typography>
        3. The WiFi setup now complete.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Typography>
        Reconnect to your working internet connection to access the app again.
        </Typography>
      </Box>
    </Box>
  );
}

export default Connection;