import { Box, Icon, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const StatBox = ({ title, subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const angle = progress * 360;
  const textColor = progress ? colors.greenAccent[400] : colors.redAccent[400];

  return (
    <Box >
      <Box
        // display="flex"
        // justifyContent="center"
        // alignItems="center"
      >
        <Box
          sx={{
            background: `radial-gradient(transparent 45%, ${textColor} 75%),
                      conic-gradient(transparent 0deg ${angle}deg, transparent ${angle}deg 360deg),
                      transparent`,
            borderRadius: "50%",
            width: `50px`,
            height: `50px`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Icon
            sx={{
              fontSize: "48px",
              mt:"-30px",
              color: textColor,
            }}
          >
            {icon}
          </Icon>
        </Box>
      </Box>
    </Box>
  );
};

export default StatBox;
