import React, { useEffect } from 'react';
import { Box, Icon, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import fastBleepSound from '../assets/sounds/fast_bleep.wav';
import falseSound from '../assets/sounds/false.wav';

const MainCircle = ({ title, subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const angle = progress * 360;
  const textColor = progress ? colors.greenAccent[200] : colors.redAccent[200];
  const textColor3 = progress ? colors.greenAccent[600] : colors.redAccent[600];
  const textColor2 = progress ? colors.greenAccent[100] : colors.redAccent[100];

//   console.log("Vacancy: ", progress);
  useEffect(() => {
    const audio = new window.Audio(progress ? fastBleepSound : falseSound);
    audio.play().catch((error) => {
      // Handle play error, if any
      console.error('Failed to play audio:', error);
    });
  }, [progress]);

  return (
    <Box >
      <Box
        // display="flex"
        // justifyContent="center"
        // alignItems="center"
      >
        <Box
          sx={{
            background: `radial-gradient(transparent 35%, ${textColor} 75%),
                      conic-gradient(transparent 0deg ${angle}deg, transparent ${angle}deg 360deg),
                      transparent`,
            borderRadius: "50%",
            width: `300px`,
            height: `300px`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon
            sx={{
              fontSize: "100px",
              color: textColor,
            }}
          >
            {icon}
          </Icon>

          <Box
            display="flex"
            flexDirection="row" // Display "title" and "increase" components in a row
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h1"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {title}
            </Typography>
            <Typography
              variant="h3"
              fontStyle="bold"
              sx={{ color: colors.blueAccent[600] }}
            >
              {increase}
            </Typography>
            <Typography
              variant="h2"
              fontStyle="bold"
              sx={{ color: textColor2 }}
            >
              {progress ? " VACANT" : " OCCUPIED"}
            </Typography>
          </Box>

          <Typography variant="h5" sx={{ color: colors.primary[50] }} marginBottom="30px">
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MainCircle;
