import React, { useState, useEffect } from 'react';
import { IgrLinearGauge, IgrLinearGraphRange } from 'igniteui-react-gauges';
import { IgrLinearGaugeModule } from 'igniteui-react-gauges';
import { ref, onValue } from 'firebase/database';
import { database } from './firebaseConfig';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../theme';

IgrLinearGaugeModule.register();

export default function GaugeBar({ chartType }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [autoData, setAutoData] = useState({});
  const [autoDataMax, setAutoDataMax] = useState({});

  useEffect(() => {
    const deviceDataRef = ref(database, 'Device');
    onValue(deviceDataRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setData(data);
        setLoading(false); // Set loading to false when data is loaded
      }
    });
  }, []);

  useEffect(() => {
    const autoDataRef = ref(database, 'AutomationMin');

    onValue(autoDataRef, (snapshot) => {
      if (snapshot.exists()) {
        const autoData = snapshot.val();
        setAutoData(autoData);
      }
    });
  }, []);

  useEffect(() => {
    const autoDataRefMax = ref(database, 'AutomationMax');

    onValue(autoDataRefMax, (snapshot) => {
      if (snapshot.exists()) {
        const autoDataMax = snapshot.val();
        setAutoDataMax(autoDataMax);
      }
    });
  }, []);

  const dataValue = data ? data[chartType] : 0;
  const markerValue = autoData[chartType] ?? 0;
  const markerMaxValue = autoDataMax[chartType] ?? 0;
  let minVal = 0;
  let maxVal = 60;

  if (chartType === 'TempEnv') {
    minVal = 0;
    maxVal = 60;
  } else if (chartType === 'TempCom') {
    minVal = 0;
    maxVal = 60;
  }

  return (
    <Box>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <IgrLinearGauge
          height="40px"
          width="250px"
          backingBrush={colors.primary[950]}
          backingOutline={colors.primary[950]}
          tickBrush={colors.primary[50]}
          minorTickBrush={colors.primary[50]}
          fontBrush={colors.blueAccent[200]}
          needleBrush={colors.blueAccent[600]}
          needleOutline={colors.grey[900]}
          minimumValue={minVal}
          value={dataValue}
          maximumValue={maxVal}
          interval={(maxVal - minVal) / 5}
          rangeBrushes={`#f1807e, ${colors.greenAccent[300]}`}
          rangeOutlines={`#f1807e, ${colors.greenAccent[300]}`}
        >
          <IgrLinearGraphRange
            key="range1"
            startValue={minVal}
            endValue={markerValue}
            innerStartExtent={0.075}
            innerEndExtent={0.075}
            outerStartExtent={0.5}
            outerEndExtent={0.5}
          />
          <IgrLinearGraphRange
            key="range2"
            startValue={markerValue}
            endValue={markerMaxValue}
            innerStartExtent={0.075}
            innerEndExtent={0.075}
            outerStartExtent={0.5}
            outerEndExtent={0.5}
          />
          <IgrLinearGraphRange
            key="range3"
            startValue={markerMaxValue}
            endValue={maxVal}
            innerStartExtent={0.075}
            innerEndExtent={0.075}
            outerStartExtent={0.5}
            outerEndExtent={0.5}
          />
        </IgrLinearGauge>
      )}
    </Box>
  );
}
