import React,{ useState, useEffect } from 'react';
import { onValue, ref, set } from 'firebase/database';
import { database } from "../../components/firebaseConfig";
import { Box, useTheme, Switch, Typography } from "@mui/material";
import Header from "../../components/Header";
import { motion } from "framer-motion"; 
import StatBox from "../../components/StatBox";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import MainCircle from "../../components/MainCircle";
import SensorOccupiedOutlinedIcon from '@mui/icons-material/SensorOccupiedOutlined';
import AirIcon from '@mui/icons-material/Air';
import Control from "../../components/Control";
import { tokens } from "../../theme";
import RecyclingOutlinedIcon from '@mui/icons-material/RecyclingOutlined';
import {Air} from "@mui/icons-material";
import TempCircle from "../../components/TempCircle";
import SanitizerOutlinedIcon from '@mui/icons-material/SanitizerOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import { Animator } from '@arwes/react-animator';
import { GridLines, Dots, MovingLines } from '@arwes/react-bgs';



const Animation = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // const [rotate, setRotate] = React.useState(false);
    const [deviceData, setDeviceData] = useState([]);
    const [checked, setChecked] = useState({});
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const [isVisible3, setIsVisible3] = useState(false);
    const [isVisible4, setIsVisible4] = useState(false);
    const [isVisible5, setIsVisible5] = useState(false);
    const [isVisible6, setIsVisible6] = useState(false);
    const [autoData, setAutoData] = useState(null); 
    const [autoDataMax, setAutoDataMax] = useState(null); 
    const [tempCom, setTempCom] = useState(null);
    const [tempEnv, setTempEnv] = useState(null);

    const handleButtonClick = () => {
        // Toggle the visibility state
        setIsVisible(!isVisible);
        setIsVisible2(false);
        setIsVisible3(false);
        setIsVisible4(false);
    };
    
    const handleButtonClick2 = () => {
        // Toggle the visibility state
        setIsVisible2(!isVisible2);
        setIsVisible(false);
        setIsVisible3(false);
        setIsVisible4(false);
    };
    
    const handleButtonClick3 = () => {
        // Toggle the visibility state
        setIsVisible3(!isVisible3);
        setIsVisible(false);
        setIsVisible2(false);
        setIsVisible4(false);
    };
    
    const handleButtonClick4 = () => {
        // Toggle the visibility state
        setIsVisible4(!isVisible4);
        setIsVisible(false);
        setIsVisible2(false);
        setIsVisible3(false);
    };

    const handleButtonClick5 = () => {
        // Toggle the visibility state
        setIsVisible5(!isVisible5);
        setIsVisible(false);
        setIsVisible2(false);
        setIsVisible3(false);
        setIsVisible4(false);
    };

    const handleButtonClick6 = () => {
        // Toggle the visibility state
        setIsVisible6(!isVisible6);
        setIsVisible(false);
        setIsVisible2(false);
        setIsVisible3(false);
        setIsVisible4(false);
    };
    

    useEffect(() => {
        const deviceDataRef = ref(database, 'Device');
    
        // Subscribe to real-time updates
        const unsubscribe = onValue(deviceDataRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                setDeviceData(data);
    
                const initialChecked = {};
                Object.keys(data).forEach((key) => {
                    initialChecked[key] = data[key];
                });
                setChecked(initialChecked);
            }
        });
    
        // Clean up the subscription when the component unmounts
        return () => {
            unsubscribe();
        };
    }, []);
    

    useEffect(() => {
        // Automatically toggle Sanitizer and Ventilation based on their respective timers
        const sanitizerIntervalId = setInterval(() => {
          // Check if the Sanitizer timer is greater than 0
          if (deviceData && deviceData.SanTimer > 0) {
            // Call your function to handle the change
            handleChange('Sanitizer', true); // Assuming you want to toggle Sanitizer to true
          }
        }, (deviceData && deviceData.SanTimer || 0) * 1000); // Convert minutes to milliseconds
      
        const ventilationIntervalId = setInterval(() => {
          // Check if the Ventilation timer is greater than 0
          if (deviceData && deviceData.VentTimer > 0) {
            // Call your function to handle the change
            handleChange('Vent', true); // Assuming you want to toggle Ventilation to true
          }
        }, (deviceData && deviceData.VentTimer || 0)* 1000); // Convert minutes to milliseconds
      
        // Cleanup function to clear the intervals when the component unmounts
        return () => {
          clearInterval(sanitizerIntervalId);
          clearInterval(ventilationIntervalId);
        };
    }, [deviceData]);

    const handleChange = (key, isChecked) => {
        setChecked((prevChecked) => {
          const newChecked = { ...prevChecked, [key]: isChecked };

          // Check if the key is 'Sanitizer' or 'Ventilation' and isChecked is true
          if ((key === 'Sanitizer' || key === 'Vent') && isChecked) {
            // Check if the corresponding duration is greater than 0
            const durationKey = key === 'Sanitizer' ? 'SanitizerDuration' : 'VentDuration';
            const duration = deviceData[durationKey] || 0;
      
            if (duration > 0) {
              // Activate the device
              set(ref(database, `Device/${key}`), true).then(() => {
                // Schedule the switch back to false after the duration
                setTimeout(() => {
                  set(ref(database, `Device/${key}`), false);
                  // Update the checked state after turning it off
                  setChecked((prevChecked) => ({ ...prevChecked, [key]: false }));
                }, duration * 1000); // Convert seconds to milliseconds
              });
            } else {
              // Update the database immediately if the duration is 0
              set(ref(database, `Device/${key}`), isChecked);
            }
          } else {
            // For other keys, update the database immediately
            set(ref(database, `Device/${key}`), isChecked);
          }
      
          return newChecked;
        });
      };      

    useEffect(() => {
        const autoDataRef = ref(database, 'AutomationMin');
    
        onValue(autoDataRef, (snapshot) => {
          if (snapshot.exists()) {
            const autoData = snapshot.val();
            setAutoData(autoData);
          }
        });
      }, []);
  
    useEffect(() => {
        const autoDataRefMax = ref(database, 'AutomationMax');
  
        onValue(autoDataRefMax, (snapshot) => {
          if (snapshot.exists()) {
            const autoDataMax = snapshot.val();
            setAutoDataMax(autoDataMax);
          }
        });
      }, []);

    useEffect(() => {
        // Check if autoData and autoDataMax are not null before proceeding
        if (autoData && autoDataMax) {
            const calculatedTempCom = deviceData.TempCom < autoData.TempCom || deviceData.TempCom > autoDataMax.TempCom ? false : true;
            setTempCom(calculatedTempCom);
            const calculatedTempEnv = deviceData.TempEnv < autoData.TempEnv || deviceData.TempEnv > autoDataMax.TempEnv ? false : true;
            setTempEnv(calculatedTempEnv);
        }
        }, [deviceData, autoData, autoDataMax]);

    useEffect(() => {
        // Update Music when Vacancy changes
        if (deviceData.Vacancy !== undefined) {

            if (!deviceData.Vacancy) {
                set(ref(database, 'Device/VisitorCount'), deviceData.VisitorCount + 1);
              }
        }
    }, [deviceData.Vacancy]);

    const calculateTempCom = () => {
        // Use the state value instead of deviceData.TempCom directly
        return tempCom;
      };
    const calculateTempEnv = () => {
        // Add your logic here to determine the progress based on TempCom value
        return tempEnv;
    };

    // useEffect(() => {
    //     // Update devices when Vacancy changes
    //     if (deviceData && deviceData.Vacancy !== undefined) {
    //       // Read switch values from the database
    //       const switchRef = ref(database, 'Switch/');
    //       onValue(switchRef, (snapshot) => {
    //         if (snapshot.exists()) {
    //           const switchData = snapshot.val();
      
    //           // Use switch values to decide which devices to update
    //           const musicValue = !deviceData.Vacancy;
      
    //           if (switchData.Vent) {
    //             set(ref(database, 'Device/Vent'), musicValue);
    //           }
    //         }
    //       });
    //     }
    //   }, [deviceData]);


    return (
        <Animator duration={{ interval: 10 }}>
        <Box
            style={{
                position: 'relative',
                width: '100vw',
                height: '100vh',
            }}
        >
             <Box style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: '#000906',
                backgroundImage: 'radial-gradient(85% 85% at 50% 50%, hsla(185, 100%, 25%, 0.25) 0%, hsla(185, 100%, 25%, 0.12) 50%, hsla(185, 100%, 25%, 0) 100%)',
                zIndex:-1
            }}>
                <GridLines
                lineColor='hsla(180, 100%, 75%, 0.05)'
                distance={30}
                />
                <Dots
                color='hsla(180, 100%, 75%, 0.05)'
                distance={30}
                />
                <MovingLines
                lineColor='hsla(180, 100%, 75%, 0.07)'
                distance={30}
                sets={20}
                />
            </Box>
            <Box display ="flex"  justifyContent="center" alignItems="center" sx={{ height: "100px",}}>
                <Header title="SMART ECO TOILET"/>
            </Box>

            {/* MainCircle */}
            <motion.div
                onClick={handleButtonClick4}
                style={{
                    position: "absolute",
                    top: '28%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <MainCircle
                    // title="Vacant"
                    icon={
                        <SensorOccupiedOutlinedIcon
                            sx={{ fontSize: "100px", mb:"3px"}}
                        />
                        }
                    // increase="Status: "
                    progress={deviceData.Vacancy}
                    subtitle={`Visitor Count: ${deviceData.VisitorCount}`}
                    tempEnv={deviceData.TempEnv}
                    tempCom={deviceData.TempCom}
                />
            </motion.div>
            {/* Statebox TempEnv */}
            <motion.div 
                onClick={handleButtonClick5}
                drag
                whileDrag={{scale:1.1}}
                whileTap={{ scale: 1.5 }}
                dragConstraints={{
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom:0,
                }}
                style={{
                    position: "absolute",
                    top: '56%',
                    left: '10%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <StatBox
                    title="20"
                    subtitle="Moisture"
                    progress={calculateTempEnv()}
                    increase="%"
                    icon={
                    <ThermostatIcon
                        sx={{ fontSize: "26px", mb:"3px"}}
                    />
                    }
                />
            </motion.div>
            {/* Statebox Heater */}
            <motion.div
                onClick={handleButtonClick2}
                drag
                whileDrag={{scale:1.1}}
                whileTap={{ scale: 1.5 }}
                dragConstraints={{
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom:0,
                }} 
                style={{
                    position: "absolute",
                    top: '61%',
                    left: '29%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <StatBox
                    title="20"
                    subtitle="Moisture"
                    progress={deviceData.Heater}
                    increase="%"
                    icon={
                    <LocalFireDepartmentOutlinedIcon
                        sx={{ fontSize: "26px", mb:"3px"}}
                    />
                    }
                />
            </motion.div>
            {/* Statebox Sanitizer */}
            <motion.div
            onClick={handleButtonClick}
                drag
                whileDrag={{scale:1.1}}
                whileTap={{ scale: 1.5 }}
                dragConstraints={{
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom:0,
                }}
                style={{
                    position: "absolute",
                    top: '62%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <StatBox
                    title="20"
                    subtitle="Moisture"
                    progress={deviceData.Sanitizer}
                    increase="%"
                    icon={
                    <SanitizerOutlinedIcon
                        sx={{ fontSize: "26px", mb:"3px"}}
                    />
                    }
                />
            </motion.div>
            {/* Statebox Vent */}
            <motion.div
                onClick={handleButtonClick3}
                drag
                whileDrag={{scale:1.1}}
                whileTap={{ scale: 1.5 }}
                dragConstraints={{
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom:0,
                }}
                style={{
                    position: "absolute",
                    top: '61%',
                    left: '71%',
                    transform: 'translate(-50%, -50%)',
                    // zIndex: 2
                }}
            >
                <StatBox
                    title="20"
                    subtitle="Moisture"
                    progress={deviceData.Vent}
                    increase="%"
                    icon={
                    <AirIcon
                        sx={{ fontSize: "26px", mb:"3px"}}
                    />
                    }
                />
            </motion.div>
            {/* Statebox TempCom */}
            <motion.div
                onClick={handleButtonClick6}
                drag
                whileDrag={{scale:1.1}}
                whileTap={{ scale: 1.5 }}
                dragConstraints={{
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom:0,
                }}
                style={{
                    position: "absolute",
                    top: '56%',
                    left: '90%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                }}
            >
                <StatBox
                    title="20"
                    subtitle="Moisture"
                    progress={calculateTempCom()}
                    increase="%"
                    icon={
                    <RecyclingOutlinedIcon
                        sx={{ fontSize: "26px", mb:"3px"}}
                    />
                    }
                />
            </motion.div>
            {/* Control Sanitizer */}
            <motion.div
                initial={{ opacity: 0, y: -100 }}
                animate={{
                opacity: isVisible ? 1 : 0,
                y: isVisible ? 0 : -50,
                zIndex: isVisible ? 1 : -1,
                // scale: isVisible ? 1 : 0,
                }}
                transition={{ duration: 0.5 }}
                style={{
                    position: "absolute",
                    left: "50%", // X-coordinate
                    top: "70%", // Y-coordinate
                width: 100,
                height: 100,
                marginTop: 20,
                }}
            >
                <motion.div
                    drag
                    dragConstraints={{
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom:0,
                    }}
                >
                    <Control
                        title="Dosing"
                        progress={deviceData.Sanitizer}
                        increase="Status: "
                        icon={<SanitizerOutlinedIcon sx={{ fontSize: "40px", mt: "2px" }} />}
                        onChange={(isChecked) => handleChange('Sanitizer', isChecked)}
                        duration={deviceData.SanitizerDuration}
                    />
                </motion.div>
            </motion.div>
            {/* Control Heater */}
            <motion.div
                initial={{ opacity: 0, y: -100 }}
                animate={{
                opacity: isVisible2 ? 1 : 0,
                y: isVisible2 ? 0 : -50,
                zIndex: isVisible2 ? 1 : -1,
                }}
                transition={{ duration: 0.5 }}
                style={{
                    position: "absolute",
                    left: "50%", // X-coordinate
                    top: "70%", // Y-coordinate
                width: 100,
                height: 100,
                marginTop: 20,
                }}
            >
                <motion.div
                    drag
                    dragConstraints={{
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom:0,
                    }}
                >
                    <Control
                        title="Heater"
                        progress={deviceData.Heater}
                        increase="Status: "
                        icon={<LocalFireDepartmentOutlinedIcon sx={{ fontSize: "40px", mt:"-5px"}}/>}
                        onChange={(isChecked) => handleChange('Heater', isChecked)}
                    />
                </motion.div>
            </motion.div>
            {/* Control Vent */}
            <motion.div
                initial={{ opacity: 0, y: -100 }}
                animate={{
                opacity: isVisible3 ? 1 : 0,
                y: isVisible3 ? 0 : -50,
                zIndex: isVisible3 ? 1 : -1,
                }}
                transition={{ duration: 0.5 }}
                style={{
                    position: "absolute",
                    left: "50%", // X-coordinate
                    top: "70%", // Y-coordinate
                    transform: 'translate(-50%, -50%)',
                    width: 100,
                    height: 100,
                    marginTop: 20,
                }}
            >
                <motion.div
                    drag
                    dragConstraints={{
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom:0,
                    }}
                >
                    <Control
                        title="Ventilation"
                        progress={deviceData.Vent}
                        increase="Status: "
                        icon={<Air sx={{ fontSize: "40px", mt:"-5px"}}/>}
                        onChange={(isChecked) => handleChange('Vent', isChecked)}
                        duration={deviceData.VentDuration}
                    />
                </motion.div>
            </motion.div>
            {/* Control Vacancy */}
            {/* <motion.div
                initial={{ opacity: 0, y: -100 }}
                animate={{
                opacity: isVisible4 ? 1 : 0,
                y: isVisible4 ? 0 : -50,
                zIndex: isVisible4 ? 1 : -1,
                }}
                transition={{ duration: 0.5 }}
                style={{
                    position: "absolute",
                    left: "50%", // X-coordinate
                    top: "70%", // Y-coordinate
                    transform: 'translate(-50%, -50%)',
                    width: 100,
                    height: 100,
                    marginTop: 20,
                }}
            >
                <motion.div
                    drag
                    dragConstraints={{
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom:0,
                    }}
                >
                   <Control
                        title="Vacancy"
                        progress={deviceData.Vacancy}
                        increase="Status: "
                        icon={<SensorOccupiedOutlinedIcon sx={{ fontSize: "40px", mt:"-5px"}}/>}
                        onChange={(isChecked) => handleChange('Vacancy', isChecked)}
                    />
                </motion.div>
            </motion.div> */}
            {/* TempCircle Env */}
            <motion.div
                animate={{
                    x: isVisible5 ? [0, -10, 10, -10, 10, 0] : 0,
                }}
                transition={{ duration: 0.5 }}
                style={{
                    position: "absolute",
                    left: "15%", // X-coordinate
                    top: "45%", // Y-coordinate
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                width: 100,
                height: 100,
                }}
            >
                <motion.div
                    drag
                    dragConstraints={{
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom:0,
                    }}
                >
                    <TempCircle
                        title={deviceData.TempEnv}
                        subtitle="Env. Temp"
                        progress={calculateTempEnv()}
                        // increase="Status: "
                        icon={
                        <ThermostatIcon
                            sx={{ fontSize: "35px", mt:"-3px"}}
                        />
                        }
                    />        
                </motion.div>
            </motion.div>
            {/* TempCircle Com */}
            <motion.div
                animate={{
                    x: isVisible6 ? [0, -10, 10, -10, 10, 0] : 0,
                }}
                transition={{ duration: 0.5 }}
                style={{
                    position: "absolute",
                    left: "85%", // X-coordinate
                    top: "45%", // Y-coordinate
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                width: 100,
                height: 100,
                }}
            >
                <motion.div
                    drag
                    dragConstraints={{
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom:0,
                    }}
                >
                    <TempCircle
                        title={deviceData.TempCom}
                        subtitle="Com. Temp"
                        progress={calculateTempCom()}
                        // increase="Status: "
                        icon={
                        <RecyclingOutlinedIcon
                            sx={{ fontSize: "35px", mt:"-3px"}}
                        />
                        }
                    />
                </motion.div>
            </motion.div>
        </Box>
        </Animator>
    );
}

export default Animation;
