import React, { useEffect } from 'react';
import { Box, Icon, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import fastBleepSound from '../assets/sounds/fast_bleep.wav';
import falseSound from '../assets/sounds/false.wav';

const TempCircle = ({ title, subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const angle = progress * 360;
  const textColor = progress ? colors.greenAccent[500] : colors.redAccent[500];
  const textColor2 = progress ? colors.greenAccent[200] : colors.redAccent[200];

//   console.log("Vacancy: ", progress);

  useEffect(() => {
    const audio = new window.Audio(progress ? fastBleepSound : falseSound);
    audio.play().catch((error) => {
      // Handle play error, if any
      console.error('Failed to play audio:', error);
    });
  }, [progress]);

  return (
    <Box >
      <Box>
        <Box
          sx={{
            background: `radial-gradient(transparent 45%, ${textColor} 75%),
                      conic-gradient(transparent 0deg ${angle}deg, transparent ${angle}deg 360deg),
                      transparent`,
            borderRadius: "50%",
            width: `110px`,
            height: `110px`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mb:"-50px",
            position: "absolute",
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Icon
            sx={{
              marginTop: "-40px",
              fontSize: "60px",
              color: textColor,
            }}
          >
            {icon}
          </Icon>
          <Typography variant="h6" sx={{ color: colors.primary[50] }} marginBottom="0px">
            {subtitle}
          </Typography>
          <Typography
            mt="-5px"
            variant="h3"
            fontWeight="bold"
            sx={{ color: textColor2}}
          >
            {title} °C
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TempCircle;
