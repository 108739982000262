import React, { useState, useEffect } from 'react';
import { database } from '../../components/firebaseConfig';
import { onValue, ref, set } from 'firebase/database';
import { IconButton, Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import GaugeBar from '../../components/GaugeBar';
import SettingBox from '../../components/SettingBox';
import { Air } from '@mui/icons-material';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import TempCircle from '../../components/TempCircle';
import RecyclingOutlinedIcon from '@mui/icons-material/RecyclingOutlined';
import Switch from '@mui/material/Switch';
// import axios from 'axios';

const Setting = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState(null); 
  const [autoData, setAutoData] = useState(null); 
  const [autoDataMax, setAutoDataMax] = useState(null); 
  const [tempCom, setTempCom] = useState(null);
  const [tempEnv, setTempEnv] = useState(null);
  // const [timeLeft, setTimeLeft] = useState(null);
  // const [wifiName, setWifiName] = useState('');
  // const [wifiPassword, setWifiPassword] = useState('');
  const [switchStates, setSwitchStates] = useState([]);
  const [checked, setChecked] = useState({});

  // const sendWifiCredentials = async () => {
  //   try {
  //     await axios.post('http://192.168.27.196/wifi', {
  //       name: wifiName,
  //       password: wifiPassword,
  //     });
  //     console.log('Wi-Fi credentials sent successfully');
  //   } catch (error) {
  //     console.error('Error sending Wi-Fi credentials:', error);
  //   }
  // };

  useEffect(() => {
    const deviceDataRef = ref(database, 'Device/');
    onValue(deviceDataRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setData(data);  
      }
    });
  }, []);

  useEffect(() => {
    const deviceDataRef = ref(database, 'Switch'); // Adjust the path as needed

    onValue(deviceDataRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setSwitchStates(data);

        // Initialize the checked state based on the keys of your devices
        const initialChecked = {};
        Object.keys(data).forEach((key) => {
          initialChecked[key] = data[key];
        });
        setChecked(initialChecked);
      }
    });
  }, []);

  const handleChange = (key, isChecked) => {
    setChecked({ ...checked, [key]: isChecked });

    // Update the corresponding data in Firebase
    const dataRef = ref(database, `Switch/${key}`); // Adjust the path as needed
    set(dataRef, isChecked);
  };

  useEffect(() => {
    const autoDataRef = ref(database, 'AutomationMin'); 

    onValue(autoDataRef, (snapshot) => {
      if (snapshot.exists()) {
        const autoData = snapshot.val();
        setAutoData(autoData);
      }
    });
  }, []);

  useEffect(() => {
    const autoDataRefMax = ref(database, 'AutomationMax');

    onValue(autoDataRefMax, (snapshot) => {
      if (snapshot.exists()) {
        const autoDataMax = snapshot.val();
        setAutoDataMax(autoDataMax);
      }
    });
  }, []);

  useEffect(() => {
    // Check if autoData and autoDataMax are not null before proceeding
    if (autoData && autoDataMax) {
        const calculatedTempCom = data.TempCom < autoData.TempCom || data.TempCom > autoDataMax.TempCom ? false : true;
        setTempCom(calculatedTempCom);
        const calculatedTempEnv = data.TempEnv < autoData.TempEnv || data.TempEnv > autoDataMax.TempEnv ? false : true;
        setTempEnv(calculatedTempEnv);
    }
    }, [data, autoData, autoDataMax]);


  const increaseTimer = () => {
    if (data) {
      const updatedTemperature = (data.VentTimer || 0) + 1;;
      set(ref(database, 'Device/VentTimer'), updatedTemperature);
    }
  };
  const decreaseTimer = () => {
    if (data) {
      const updatedTemperature = data.VentTimer - 1;
      set(ref(database, 'Device/VentTimer'), updatedTemperature);
    }
  };

  const increaseDuration = () => {
    if (data) {
      const updatedTemperature = data.VentDuration + 1;
      set(ref(database, 'Device/VentDuration'), updatedTemperature);
    }
  };
  const decreaseDuration = () => {
    if (data) {
      const updatedTemperature = data.VentDuration - 1;
      set(ref(database, 'Device/VentDuration'), updatedTemperature);
    }
  };

  const increaseTimerSan = () => {
    if (data) {
      const updatedTemperature = data.SanTimer + 1;
      set(ref(database, 'Device/SanTimer'), updatedTemperature);
    }
  };
  
  const decreaseTimerSan = () => {
    if (data) {
      const updatedTemperature = data.SanTimer - 1;
      set(ref(database, 'Device/SanTimer'), updatedTemperature);
    }
  };

  const increaseDurationSan = () => {
    if (data) {
      const updatedTemperature = data.SanitizerDuration  + 1;
      set(ref(database, 'Device/SanitizerDuration'), updatedTemperature);
    }
  };
  const decreaseDurationSan = () => {
    if (data) {
      const updatedTemperature = data.SanitizerDuration  - 1;
      set(ref(database, 'Device/SanitizerDuration'), updatedTemperature);
    }
  };

  const increaseTemperatureMin = () => {
    if (autoData) {
      const updatedTemperature = autoData.TempEnv + 1;
      set(ref(database, 'AutomationMin/TempEnv'), updatedTemperature);
    }
  };
  const decreaseTemperatureMin = () => {
    if (autoData) {
      const updatedTemperature = autoData.TempEnv - 1;
      set(ref(database, 'AutomationMin/TempEnv'), updatedTemperature);
    }
  };

  const increaseTemperatureComMin = () => {
    if (autoData) {
      const updatedTemperature = autoData.TempCom + 1;
      set(ref(database, 'AutomationMin/TempCom'), updatedTemperature);
    }
  };
  const decreaseTemperatureComMin = () => {
    if (autoData) {
      const updatedTemperature = autoData.TempCom - 1;
      set(ref(database, 'AutomationMin/TempCom'), updatedTemperature);
    }
  };

  const increaseTemperatureMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.TempEnv + 1;
      set(ref(database, 'AutomationMax/TempEnv'), updatedTemperature);
    }
  };
  const decreaseTemperatureMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.TempEnv - 1;
      set(ref(database, 'AutomationMax/TempEnv'), updatedTemperature);
    }
  };

  const increaseTemperatureComMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.TempCom + 1;
      set(ref(database, 'AutomationMax/TempCom'), updatedTemperature);
    }
  };
  const decreaseTemperatureComMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.TempCom - 1;
      set(ref(database, 'AutomationMax/TempCom'), updatedTemperature);
    }
  };

  const calculateTempCom = () => {
    // Use the state value instead of deviceData.TempCom directly
    return tempCom;
  };
  const calculateTempEnv = () => {
      // Add your logic here to determine the progress based on TempCom value
      return tempEnv;
  };

  const handleResetVisitorCount = () => {
    set(ref(database, 'Device/VisitorCount'), 0);
  };
  
  return (
    <Box m="10px" alignItems="center" justifyContent="center">
      {/* HEADER */}
      {/* <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "70px",}}>
        <Header title="Wifi Connection"/>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Header subtitle="IOT connection :"/>
      </Box>
        <div>
        <label>
          Wi-Fi Name:
          <input type="text" value={wifiName} onChange={(e) => setWifiName(e.target.value)} />
        </label>
        <br />
        <label>
          Wi-Fi Password:
          <input type="password" value={wifiPassword} onChange={(e) => setWifiPassword(e.target.value)} />
        </label>
        <br />
        <button onClick={sendWifiCredentials}>Send Wi-Fi Credentials</button>
      </div> */}
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "70px",}}>
        <Header title="AUTOMATION"/>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Header subtitle="MANUAL / AUTO"/>
      </Box>
      {Object.keys(switchStates).map((key) => (
            <Box
              key={key}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {key}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {switchStates[key] ? 'ON' : 'OFF'}
                </Typography>
              </Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>{checked[key] ? 'ON' : 'OFF'}</div>
                  <Switch
                    checked={checked[key]}
                    onChange={() => handleChange(key, !checked[key])}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
              </Box>
            </Box>
          ))}
      <Box mt="50px" display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Header title="VENTILATION CTRL"/>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Header subtitle="Timer and Duration"/>
      </Box>
      {/* <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        ml="70px"
        mr="70px"
        mb="10px"
        sx={{ height: "30px",color: `${colors.grey[200]}`, backgroundColor: `${colors.primary[800]}`}}
      >
        <h1>Time left: {Math.ceil(timeLeft / 1000)} sec</h1>
      </Box> */}

      <Box
        display="grid"
        gridTemplateColumns="repeat(8, 1fr)"
        // gridAutoRows="140px"
        gap="10px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px"
          sx={{ backgroundColor:colors.blueAccent[700] }}
        >
          <SettingBox
            title={data ? (data.Vent ? 'ON' : 'OFF') : 'N/A'}
            subtitle={`Ventilation`}
            // progress={`${data ? data.Vent/100 : 'N/A'}`}
            increase=""
            icon={
              <Air
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseTimer}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            Timer
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${data && data.VentTimer > 0 ? data.VentTimer + ' sec' : 'DISABLED'}`}
            </Typography>
            <IconButton onClick={decreaseTimer}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseDuration}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            Duration
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${data && data.VentDuration > 0 ? data.VentDuration + ' sec' : 'DISABLED'}`}
            </Typography>
            <IconButton onClick={decreaseDuration}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
      </Box>
      <Box mt="50px" display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Header title="DOSING CTRL"/>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Header subtitle="Timer and Duration"/>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(8, 1fr)"
        // gridAutoRows="140px"
        gap="10px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px"
          sx={{ backgroundColor:colors.blueAccent[700] }}
        >
          <SettingBox
            title={data ? (data.Sanitizer ? 'ON' : 'OFF') : 'N/A'}
            subtitle={`Sanitizer`}
            // progress={`${data ? data.Vent/100 : 'N/A'}`}
            increase=""
            icon={
              <Air
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseTimerSan}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            Timer
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${data && data.SanTimer > 0 ? data.SanTimer + ' sec' : 'DISABLED'}`}
            </Typography>
            <IconButton onClick={decreaseTimerSan}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseDurationSan}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            Duration
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${data && data.SanitizerDuration > 0 ? data.SanitizerDuration + ' sec' : 'DISABLED'}`}
            </Typography>
            <IconButton onClick={decreaseDurationSan}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        
        
      </Box>
      <Box mt="50px" display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Header title="TEMPERATURE CTRL"/>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Header subtitle="Adjust Optimum Temperature"/>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(8, 1fr)"
        // gridAutoRows="140px"
        gap="10px"
      >
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseTemperatureMin}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MIN
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoData.TempEnv+' °C' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreaseTemperatureMin}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
          gridColumn="span 4"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px"
        >
          <TempCircle
            title={`${data ? data.TempEnv : 'N/A'}`}
            subtitle="Env. Temp"
            progress={calculateTempEnv()}
            // increase="Status: "
            icon={
            <ThermostatIcon
                sx={{ fontSize: "35px", mt:"-3px"}}
            />
            }
          />
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseTemperatureMax}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MAX
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoDataMax.TempEnv+' °C' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreaseTemperatureMax}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
            gridColumn="span 8"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="50px"
        >
          <GaugeBar chartType="TempEnv"/>  {/* {chart} */}
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseTemperatureComMin}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MIN
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoData.TempCom+' °C' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreaseTemperatureComMin}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
          gridColumn="span 4"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px"
        >
          <TempCircle
            title={`${data ? data.TempCom : 'N/A'}`}
            subtitle="Com. Temp"
            progress={calculateTempCom()}
            // increase="Status: "
            icon={
            <RecyclingOutlinedIcon
                sx={{ fontSize: "35px", mt:"-3px"}}
            />
            }
          />
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseTemperatureComMax}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MAX
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoDataMax.TempCom+' °C' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreaseTemperatureComMax}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
            gridColumn="span 8"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="50px"
        >
          <GaugeBar chartType="TempCom"/>  {/* {chart} */}
        </Box>
      </Box>
      <Box mt="50px" display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Header title="RESET"/>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "40px" }}
        mb="50px"
      >
        <Button variant="contained" color="secondary" onClick={handleResetVisitorCount}>
          Reset Visitor Counter
        </Button>
      </Box>
    </Box>
  );
};

export default Setting;
