import React, { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {  ThemeProvider } from '@mui/material/styles';
import { auth } from "../../components/firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import InvalidLoginDialog from "../../components/invalidLogIn";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
// import AuthDetails from "../components/authDetails";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="http://www.gadangworks.com/">
        Gadang Works Sdn Bhd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const SignIn =()=>{
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSubmit =(e) => {
      e.preventDefault();
      signInWithEmailAndPassword(auth,email,password)
          .then((userCredential)=> {
              console.log(userCredential);
              navigate("/setting");
          })
          .catch((error) => {
              console.log(error);
              //how do i display popup invalid login credential here
              setIsDialogOpen(true);
          });
      
  };

  const handleCloseDialog = () => {
    // Close the dialog
    setIsDialogOpen(false);
  };

  return (
    <ThemeProvider theme={useTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
                  alt="profile-user"
                  width="300px"
                  height="100px"
                  src={`../../GADlogo.png`}
                  // style={{ cursor: "pointer", borderRadius: "50%" }}
                />
          <Typography component="h1" variant="h2" mb="40px" mt="20px" sx={{ color: 'text.primary' }}>
            Smart Eco Toilet
          </Typography>
          <Avatar sx={{ mt: -3, bgcolor: 'text.secondary' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in to access to setting
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                '& .MuiInputLabel-root.Mui-focused': {
                    color: (theme) => theme.palette.text.primary,
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: (theme) => theme.palette.text.secondary,
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)} 
              sx={{
                '& .MuiInputLabel-root.Mui-focused': {
                    color: (theme) => theme.palette.text.primary,
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: (theme) => theme.palette.text.secondary,
                },
              }}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ backgroundColor: 'white', color: 'black' }}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgotPass" variant="body2" color="text.secondary">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signUp" variant="body2" color="text.secondary">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      <InvalidLoginDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
      />
    </ThemeProvider>
  );
}

export default SignIn;