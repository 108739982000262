import React, { useState, useEffect } from 'react';
import { database } from '../../components/firebaseConfig';
import { set, onValue, ref } from 'firebase/database';
import { Box, IconButton, useTheme, useMediaQuery, Tooltip } from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useNavigate } from 'react-router-dom';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';

const Topbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const navigateToSetting = () => {
    navigate('/signIn');
  };
  const navigateToHome = () => {
    navigate('/');
  };
  const navigateToConnection = () => {
    navigate('/connection');
  };

  const [autoData, setAutoData] = useState(null);
  const [autoDataMax, setAutoDataMax] = useState(null);
  const [deviceData, setDeviceData] = useState([]);
  const [switchData, setSwitchData] = useState([]);

  useEffect(() => {
    const deviceDataRef = ref(database, 'Device');

    onValue(deviceDataRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setDeviceData(data);
      }
    });
  }, []);

  useEffect(() => {
    const autoDataRef = ref(database, 'AutomationMin');

    onValue(autoDataRef, (snapshot) => {
      if (snapshot.exists()) {
        const autoData = snapshot.val();
        setAutoData(autoData);
      }
    });
  }, []);

  useEffect(() => {
    const autoDataMaxRef = ref(database, 'AutomationMax'); 

    onValue(autoDataMaxRef, (snapshot) => {
      if (snapshot.exists()) {
        const autoDataMax = snapshot.val();
        setAutoDataMax(autoDataMax);
      }
    });
  }, []);

  useEffect(() => {
    const switchRef = ref(database, 'Switch'); 

    onValue(switchRef, (snapshot) => {
      if (snapshot.exists()) {
        const autoDataMax = snapshot.val();
        setSwitchData(autoDataMax);
      }
    });
  }, []);

  // const toggleThemeMode = () => {
  //   const newMode = theme.palette.mode === 'dark' ? 'light' : 'dark';
  //   colorMode.toggleColorMode(newMode);
  // };

  useEffect(() => {
    if (switchData.Heater&&deviceData && autoData) {
      if (deviceData.TempCom < autoData.TempCom) {
        const heaterRef = ref(database, 'Device/Heater');
        set(heaterRef, true);
      }
      if (deviceData.TempCom > autoData.TempCom+(autoDataMax.TempCom-autoData.TempCom)/2) {
        const heaterRef = ref(database, 'Device/Heater');
        set(heaterRef, false);
      }
    }
  }, [deviceData, autoData, autoDataMax]);
  

  return (
    <Box display="flex" justifyContent="space-between" p={2} 
      backgroundColor= "black"
    >
      <Box
        display="flex"
        borderRadius="3px"
        marginLeft={isMobile ? "0px" : "0px"}
      >
        <IconButton onClick={navigateToHome}>
          <HomeOutlined />
        </IconButton>
        <img
          alt="profile-user"
          width="111px"
          height="37px"
          src={`../../GADlogo.png`}
          // style={{ cursor: "pointer", borderRadius: "50%" }}
        />
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={navigateToConnection}>
          <WifiOutlinedIcon />
        </IconButton>
        {/* <Tooltip title="Notifications">
          <IconButton>
            <NotificationsOutlinedIcon />
          </IconButton>
        </Tooltip> */}
        <IconButton onClick={navigateToSetting}>
          <SettingsOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
