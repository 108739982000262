import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Animation from "./scenes/animation";
import Setting from "./scenes/setting";
import SignIn from "./scenes/signIn";
import SignUp from "./scenes/signUp";
import Connection from "./scenes/connection";
import ForgotPassword from "./scenes/forgotPass";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content">
            <Topbar />
            <Routes>
              <Route path="/" element={<Animation />} />
              <Route path="/setting" element={<Setting />} />
              <Route path="/signUp" element={<SignUp/>} />
              <Route path="/signIn" element={<SignIn/>} />
              <Route path="/connection" element={<Connection/>} />
              <Route path="/forgotPass" element={<ForgotPassword/>} />
            </Routes>
            
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
